import { useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart';

import sentinel from './sentinel';

const GSR = () => {
  const [score, setScore] = useState(sentinel.score);

  // If you change these colors, modify #gsr-gauge.disabled in
  // `src/styles/index.scss` accordingly.
  const ringColors = ['#5BE12C', '#F5CD19', '#EA4228'];
  const needleColor = '#464A4F';
  const disabledColor = '#B2BCC9';

  useEffect(() => {
    const onDetectRat = ({ score: s }) => { setScore(s); };
    setScore(sentinel.score);
    sentinel.on('detectRat', onDetectRat);
    return () => {
      sentinel.off('detectRat', onDetectRat);
    };
  }, []);

  return (
    <div className={!sentinel.isRunning ? 'disabled' : ''} id="gsr-gauge">
      <span>Genuine</span>
      <GaugeChart id="gsr-gauge-chart"
        animate={false}
        nrOfLevels={3}
        colors={sentinel.isRunning ? ringColors : [disabledColor]}
        needleColor={sentinel.isRunning ? needleColor : disabledColor}
        needleBaseColor={sentinel.isRunning ? needleColor : disabledColor}
        arcWidth={0.2}
        percent={sentinel.isRunning ? score : 0.5}
        hideText={true}
      />
      <span>Remote</span>
    </div>
  );
};

export default GSR;
