import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Browser from '../../components/Browser';
import Menu from '../../components/Menu';
import sentinel from '../../components/GSR/sentinel';

const Step4 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sentinel
      .validateTransaction()
      .then((successfulTransaction) => {
        setTimeout(() => {
          navigate('/step5', { state: { successfulTransaction } });
        }, 500);
      });
  }, []);

  return (
    <>
      <Menu activeStep={3} />
      <Browser>
        <div className="card w-2/4 bg-base-100 shadow-xl mb-16
          bg-white dark:bg-gray-900 text-black dark:text-white">
          <div className="card-body flex justify-center">
            <h2 className="text-center">Security Check</h2>
            <div className="card-actions justify-center mt-5">
              <span className="loading loading-spinner loading-lg">
              </span>
            </div>
          </div>
        </div>
      </Browser>
    </>
  );
};

export default Step4;
